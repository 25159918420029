import axios from 'axios'
import { APIINV as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/salidas`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  salidaJSON(id, params) {
    params = params || {}
    return apiClient.get(`${id}.json`, { params: params })
  },

  salidasJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/salidas.json`, { params: params })
  },

  salidaPorArticuloJson(id, params){
    params = params || {}
    return apiClient.get(`${API}/articulos/${id}/salidas.json`, { params: params })
  },

  guardar(salida) {
    return apiClient.post('', salida)
  },
}